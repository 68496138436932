/*
Version 0.135

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2019
	12-09-2019
	- [CHANGED] Changed everything to work with the XSoft Datetimepicker
	23-06-2019
	- [ADDED] Default date is now moved to the PHP.
	28-04-2019
	- [CHANGE] Added some configuration variables.
2018
	05-10-2018
	- [BORN] This class is born
	
!Wishlist
	
	- Make steps available in data-field in input HTML.
 
*/

var form_datepickers = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none

	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	
	this.onready = function(
		_inside
	) {
		
		if(loadDatepicker === 1) {
			
			/**
			Initialize datepickers in form and validate when checked date
			**/
			countDatepickers = $(_inside).find('.form-input-date').length + 1;

			if(countDatepickers >= 2) {
			
				for (i = 1; i < countDatepickers; i++) {
					
					getValue		= $(_inside).find('.form-input-date').eq(i-1).val();
					getType 		= $(_inside).find('.form-input-date').eq(i-1).data('type');
					getDefaultDate 	= $(_inside).find('.form-input-date').eq(i-1).data('defaultdate');
					
					/*
					Set: Defaults
					*/
					if(typeof getDefaultDate === 'undefined') {
						getDefaultDate = new Date(1980,1,1);
					} else {
						var parts = getDefaultDate.split('-');
						getDefaultDate = new Date(parts[2],parts[1],parts[0]); 
					}
					
					/*
					Is value already filled in?
					*/
					if(getValue !== '') {
						var parts1 = getValue.split('-');
						getDefaultDate = new Date(parts1[2],parts1[1],parts1[0]); 
					}
					
					/*
					Go on
					*/
					form_datepickers.initDatepicker(
						i,
						getDefaultDate,
						getType,
						_inside
					);
					
				}

			}
		
		}
		
		if(loadDateTimepicker === 1) {
		
			/**
			Initialize datetimepickers in form and validate when checked date and time
			**/
			countDateTimepickers = $(_inside).find('.form-input-datetime').length + 1;
			
			for (i = 1; i < countDateTimepickers; i++) {
				
				getValue		= $(_inside).find('.form-input-datetime').eq(i-1).val();
				getType			= $(_inside).find('.form-input-datetime').eq(i-1).data('type');
				getDefaultDate 	= $(_inside).find('.form-input-datetime').eq(i-1).data('defaultdate');
				
				/*
				Set: Defaults
				*/
				if(typeof getDefaultDate === 'undefined') {
					getDefaultDate = new Date(1980,1,1,10);
				} else {
					var date_parts 		= getDefaultDate.substr(0,10).split('-');
					var time_parts 		= getDefaultDate.substr(-5).split(':');
					
					getDefaultDate = new Date(date_parts[2],date_parts[1],date_parts[0],time_parts[0],time_parts[1]); 
					
				}
				
				/*
				Is value already filled in?
				*/
				if(getValue !== '') {
					var date_parts1 	= getValue.substr(0,10).split('-');
					var time_parts1 	= getValue.substr(-5).split(':');
					
					getDefaultDate = new Date(date_parts1[2],date_parts1[1],date_parts1[0],time_parts1[0],time_parts1[1]); 
				}
				
				/*
				Go on
				*/
				form_datepickers.initDateTimepicker(
					i,
					getDefaultDate,
					getType,
					_inside
				);
				
			}
			
		}
		
	};
	
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	/* FUNCTIONS **************************************************************** */
	
	/**
	Date and DateTime picker function ********************************************************************************
	**/
	this.initDatepicker = function(
		i,
		default_date,
		type,
		_inside
	) {

		//var _inside;
		
		// /**
		// Check if init is on popup
		// **/
		// if(isPopup === 'popup') {
		// 	_inside = '.js-popup-loader';
		// } else {
		// 	_inside = '.page-loader';
		// }
	
		/**
		Check if date must be a birthday or not
		**/
		if(type == 'birthday') {
	
			datePickerOptions = {
				timepicker	: false,
				format		: 'd-m-Y',
				yearRange	: '1950:2018',
				changeMonth	: true,
				changeYear	: true,
				defaultDate	: default_date,
				//monthNamesShort: $.datepicker.regional.nl.monthNames,
				onSelect: function() {
					form.validateInput('onkeyup',$(this),'date');
				}
			};
	
		} else {
			
			datePickerOptions = {
				timepicker	: false,
				format		: 'd-m-Y',
				defaultDate	: default_date,
				//monthNamesShort: $.datepicker.regional.nl.monthNames,
				onSelect: function() {
					form.validateInput('onkeyup',$(this),'date');
				}
			};
			
		}
	
		//$.datepicker.setDefaults($.datepicker.regional.nl);
		
		$(_inside).find('.form-input-date').eq(i-1).datetimepicker(datePickerOptions);
			
	};
	
	this.initDateTimepicker = function(
		i,
		default_date,
		type,
		_inside
	) {
		
		// var _inside;
		
		// /**
		// Check if init is on popup
		// **/
		// if(isPopup === 'popup') {
		// 	_inside = '.js-popup-loader';
		// } else {
		// 	_inside = '.page-loader';
		// }
		
		//$.datepicker.setDefaults($.datepicker.regional.nl);
		//$.timepicker.setDefaults($.timepicker.regional.nl);
		
		/**
		Init datetimepicker
		**/
		$(_inside).find('.form-input-datetime').eq(i-1).datetimepicker({
			datepicker	: true,
			timepicker	: true,
			format		: 'd-m-Y H:i',
			defaultDate	: default_date,
			step		: 30
		});
		
	};
	
	
}).apply(form_datepickers); 