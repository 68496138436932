/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2020
	17-02-2020
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_googlemaps = {};

(function(){
	 
	/*
	PRELOAD & DOCUMENT READY  *****************************************************************
	PRELOAD & DOCUMENT READY  *****************************************************************
	PRELOAD & DOCUMENT READY  *****************************************************************
	PRELOAD & DOCUMENT READY  *****************************************************************
	*/

	this.onready = function() {
			
		var map;

        var __center = new google.maps.LatLng(51.70356369478017, 5.212780897390912);

        var style = [
		  {
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#C6D2A2"
		      }
		    ]
		  },
		  {
		    "elementType": "labels.icon",
		    "stylers": [
		      {
		        "visibility": "off"
		      }
		    ]
		  },
		  {
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#000"
		      }
		    ]
		  },
		  {
		    "elementType": "labels.text.stroke",
		    "stylers": [
		      {
		        "color": "#f5f5f5"
		      }
		    ]
		  },
		  {
		    "featureType": "administrative.land_parcel",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#bdbdbd"
		      }
		    ]
		  },
		  {
		    "featureType": "poi",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#D8E2BB"
		      }
		    ]
		  },
		  {
		    "featureType": "poi",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#000"
		      }
		    ]
		  },
		  {
		    "featureType": "poi.park",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#e5e5e5"
		      }
		    ]
		  },
		  {
		    "featureType": "poi.park",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#000"
		      }
		    ]
		  },
		  {
		    "featureType": "road",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#E3EEC2"
		      }
		    ]
		  },
		  {
		    "featureType": "road.arterial",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#000"
		      }
		    ]
		  },
		  {
		    "featureType": "road.highway",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#dadada"
		      }
		    ]
		  },
		  {
		    "featureType": "road.highway",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#000"
		      }
		    ]
		  },
		  {
		    "featureType": "road.local",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#000"
		      }
		    ]
		  },
		  {
		    "featureType": "transit.line",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#e5e5e5"
		      }
		    ]
		  },
		  {
		    "featureType": "transit.station",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#eeeeee"
		      }
		    ]
		  },
		  {
		    "featureType": "water",
		    "elementType": "geometry",
		    "stylers": [
		      {
		        "color": "#C1CE9A"
		      }
		    ]
		  },
		  {
		    "featureType": "water",
		    "elementType": "labels.text.fill",
		    "stylers": [
		      {
		        "color": "#000"
		      }
		    ]
		  }
		];

        var mapOptions = {

            center: __center,

            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: 14,

            backgroundColor: "#e1e1e1",

            panControl: false,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL
            }

        };

        map = new google.maps.Map(document.getElementById('googlemaps-inside'), mapOptions);

        // SET THE MAP TYPE
        var mapType = new google.maps.StyledMapType(style, {
            name: "Grayscale"
        });
        map.mapTypes.set('grey', mapType);
        map.setMapTypeId('grey');

        //CREATE A CUSTOM PIN ICON
        var marker_image 	= globalUrl + '/img/static/googlemaps-marker.png';
        var pinIcon 		= new google.maps.MarkerImage(marker_image, null, null, null, new google.maps.Size(40, 40));

        marker = new google.maps.Marker({
            position: __center,
            map: map,
            icon: pinIcon,
            title: ''
        });

        /*
        Windows
        */
        var window_supernova =
	    '<div class="maps-popup">' +
		    '<strong>Prakijk Ananda</strong>' +
	    '</div>';

	  	var infowindow_supernova = new google.maps.InfoWindow({
		    content: window_supernova
	  	});
	  
	  	/*
	  	Markers
	  	*/
        // marker.addListener("mouseover", function() { 
		//     infowindow_supernova.open({
		//       anchor: marker,
		//       map,
		//       shouldFocus: false
		//     });
		// });
		
		marker.addListener("click", function() {
			window.open('https://www.google.com/maps/dir//Ananda+-+Praktijk+voor+Natuurgeneeskunde+en+Yoga,+Marijnenlaan+9,+5251+SB+Vlijmen/@51.7034302,5.1715816,13z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x47c6ed05bba4467f:0xe0283ce42208f86d!2m2!1d5.2127809!2d51.7033842?hl=nl&entry=ttu');
		});

        // marker.addListener('mouseout', () => infowindow_supernova.close());

	};
	
	/*
	METHODS *****************************************************************
	METHODS *****************************************************************
	METHODS *****************************************************************
	METHODS *****************************************************************
	*/
	
	// none yet

}).apply(specific_googlemaps); 